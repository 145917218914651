a {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

*:focus {
  outline: 3px solid orange;
}

* {
  box-sizing: border-box;
}
